<template>
	<ul class="nav">
		<li class="nav-item">
			<a href="/" aria-current="page" class="nav-link" :class="[activePage === 'home' ? 'active' : '']" @click="goHome" title="Zur Startseite">
				<span class="material-icons-outlined" aria-hidden="true">cottage</span> Start 
			</a>
		</li>
		<li class="nav-item" title="Zu der Übersicht über die Orte">
			<a href="/orte" class="nav-link" :class="[activePage === 'orte' ? 'active' : '']">
				<span class="material-icons-outlined" aria-hidden="true">place</span> Orte 
			</a>
		</li>
		<li class="nav-item" title="Zu der Übersicht über die Angebote">
			<a href="/angebote" class="nav-link" :class="[activePage === 'angebote' ? 'active' : '']" >
				<span class="material-icons-outlined" aria-hidden="true">calendar_today</span> Angebote 
			</a>
		</li>
		<li class="nav-item" title="Zu der Übersicht über die Ratgeberartikel">
			<a href="/ratgeber" class="nav-link" :class="[activePage === 'ratgeber' ? 'active' : '']">
				<span class="material-icons-outlined" aria-hidden="true">help_outline</span> Ratgeber 
			</a>
		</li>
		<li class="nav-item" title="Mehr Menüpunkte anzeigen">
			<a @click="goMore" class="nav-link" style="cursor:pointer;" :class="[activePage === 'mehr' ? 'active' : '']">
				<span class="material-icons-outlined" aria-hidden="true">more_vert</span> Mehr 
			</a>
		</li>
	</ul>
</template>
<script>

	export default {
		name: 'MobileNav',
		computed: {
			route() {
				return this.$route.path;
			},
			activePage() {
				if (this.$route.path === '/') {
					return 'home';
				} else if (this.$route.path.includes('orte')) {
					return 'orte';
				} else if (this.$route.path.includes('angebote')) {
					return 'angebote';
				} else if (this.$route.path.includes('ratgeber')) {
					return 'ratgeber';
				}

				return 'mehr';
			}
		},
		methods: {
			goHome(){
				if(this.route == "/"){
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
				else{
					this.$router.push('/')
				}
			},
			goMore(){
				if(this.route == "/mehr"){
					return this.$router.back();
				}
				else{
					this.$router.push('/mehr')
				}
			}
		}
	}

</script>
<style lang="scss" scoped>
</style>
